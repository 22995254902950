import {
  API_TYPES,
  type InitiateSurveyCallResponse,
  initiateSurveyCallResponse,
} from "@clipboard-health/contract-worker-app-bff";
import { post } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { useMutation, type UseMutationOptions } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

export const INITIATE_SURVEY_CALL_URL = `${environmentConfig.REACT_APP_WORKER_APP_BFF_URL}/pay-survey-calls`;

// TODO: Remove this once the BFF schema has it exported
export const initiateSurveyCallRequest = z.object({
  data: z.object({
    type: z.literal(API_TYPES.paySurveyCall),
    attributes: z.object({}),
  }),
});

export function useInitiateSurveyCall(
  options: UseMutationOptions<
    InitiateSurveyCallResponse,
    AxiosError<InitiateSurveyCallResponse>,
    Record<string, unknown>
  > = {}
) {
  return useMutation({
    mutationFn: async () => {
      const response = await post({
        url: INITIATE_SURVEY_CALL_URL,
        data: {
          data: {
            type: "pay-survey-call",
            attributes: {},
          },
        },
        requestSchema: initiateSurveyCallRequest,
        responseSchema: initiateSurveyCallResponse,
      });

      return response.data;
    },
    ...options,
  });
}
