import { API_TYPES, useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { utcToZonedTime } from "date-fns-tz";
import { z } from "zod";

interface GetInterviewAvailabilityParams {
  workplaceId: string;
}

export const getAvailabilityResponseSchema = z.object({
  data: z.array(
    z.object({
      id: z.string(),
      type: z.literal(API_TYPES.interviewAvailability),
      attributes: z.object({
        start: z.string(),
        end: z.string(),
      }),
    })
  ),
  links: z.object({
    next: z.string().optional(),
    prev: z.string().optional(),
  }),
});

export type GetAvailabilityResponse = z.infer<typeof getAvailabilityResponseSchema>;

export function transformInterviewAvailabilityData(
  interviewAvailability: GetAvailabilityResponse | undefined,
  workerTimezone: string
) {
  if (!interviewAvailability) {
    return [];
  }

  return interviewAvailability.data.map((availability) => {
    return {
      start: utcToZonedTime(availability.attributes.start, workerTimezone),
      end: utcToZonedTime(availability.attributes.end, workerTimezone),
    };
  });
}

export type InterviewAvailability = ReturnType<typeof transformInterviewAvailabilityData>;

export function useGetInterviewAvailability(
  params: GetInterviewAvailabilityParams,
  options?: UseGetQueryOptions<GetAvailabilityResponse>
) {
  const { workplaceId } = params;
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}/workplaces/${workplaceId}/interviews/availability`,
    responseSchema: getAvailabilityResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_INTERVIEW_AVAILABILITY_FAILURE,
    },
    ...options,
    useErrorBoundary: false,
  });
}
